import React from 'react';
import logo from './logo.svg';
import './App.css';
import './components/SubscribeForm'
import SubscribeForm from './components/SubscribeForm';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>COMING SOON</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Subscribe to stay up to date
        </p>
        <SubscribeForm></SubscribeForm>
        {/* <a
          className="App-link"
          href="mailto:flame-ripcord0h@icloud.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          CONTACT
        </a> */}
      </header>
    </div>
  );
}

export default App;

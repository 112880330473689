import { MouseEventHandler, useState } from "react"

const SUBSCRIBE_ENDPOINT = "https://uyddpk2va9.execute-api.us-east-1.amazonaws.com/Prod/subscribe/";

const submit = async (email: string): Promise<void> => {
   const response = await fetch(SUBSCRIBE_ENDPOINT, {
    method: 'PUT',
    body: email,
    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'} });

  if (!response.ok) {
    console.log("error")
  }
}

function SubscribeForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var value = event.currentTarget.value;

    if (!isValidEmail(email)) {
      setError("invalid email");
      alert(error);
    } else {
      setError(null);
      submit(email.toLowerCase())
      setEmail('')
    }
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    var value = e.currentTarget.value;
    setEmail(value);
  }

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <form onSubmit={submitForm}>
      <label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          name="name"
        />
      </label>
      <input
        type="submit"
        value="SUBSCRIBE"
        />
    </form>
  )
}

export default SubscribeForm